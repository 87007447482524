<template>
  <div class="">
    <UserForm
      ref="form"
      action="Update"
      v-model="formData"
      @formSubmitted="updateUser"
    />
  </div>
</template>
  
  <script>
import UserForm from "@/views/components/user/UserForm";
import UserService from "@/services/UserService";

export default {
  name: "updateUser",
  components: {
    UserForm,
  },
  data() {
    return {
      userService: new UserService(),
      id: this.$route.fullPath.split("/").pop(),
      formData: {},
    };
  },
  methods: {
    updateUser(e) {
      this.$refs.form.loading = true;
      this.userService
        .update(this.formData._id, e)
        .then((res) => {
          this.$router.push("/all-users");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.$refs.form.loading = false;
        });
    },
    mappingData(data) {
      return {
        ...data,
      };
    },
    getUser() {
      this.userService
        .show(this.id)
        .then((res) => {
          this.formData = this.mappingData(res);
          this.formData.topics = this.formData.topics.map((e) => e._id);
          this.formData.languages = this.formData.languages.map((e) => e._id);
        })
        .catch((err) => {})
        .finally(() => {});
    },
  },
  mounted() {
    this.getUser();
  },
};
</script>
  
  <style scoped>
</style>
  